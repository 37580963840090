import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Not found what you're looking for?</h1>
    <p>
      View <a href="/">homepage</a>.
    </p>
  </Layout>
)

export default NotFoundPage
